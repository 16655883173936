export const shapes = {
	HEART: "e0QcL!$z$*!r$^cv$vc0v1c01",
	ROUND: "1MYfU3$0$!f$T$$D$`$v$*f$w$$zY07!",
	HASH: "6gMc6py1$$!f6pzN$$!f6pzN09y1",
	HOLE: "$MA9$f$$7PZ!$*0020",
	PLUS: "70A97Mse$f$$73xM",
	RUBY: "fwA8$XY$Yf7T011M",
	GRID: "lgA9nZl$$lvRlvZl",
	ABOUT: "vMAe$bY$73x!7zY!00seUc01",
	NINE: "XwIbKZ$ZXMW0KfLt$uU0wbLv",
	CURVE: "$0A9u$tKXZWZ7XJT0020",
	ROOK: "GwA9WL$$!leBoYrs",
	EGGO: "$MIb$RvR$R$l$nZlZvZlwfZn",
	CURL: "vMMbMf$LT$IZTpKZMtKZv$yL0020",
	STAR: "0wQc0h003N$0!b$$!8f$X!c$w1Ss0^M",
	BOLT: "3MUe03$MM0fY$M0$f$$PM07Y40071c010200",
	BAT: "4wYb4kugLzue$`q$^44aI0414",
	PAC: "3MMdv$W3$^T!e3f$!0fv$$73$XP0020",
	NINJA: "0gIb76a0Y0tTY0tT8NNT0c00",
	PINE: "0wQf0h0*X0M0vU!0d$$!4$^Mvw$$L03W0",
	SKULL: "7wEbYYcfoUP$P!spMwYP003g",
	BONE: "o0U7$Y0r$J$$Mf$$w07S",
	OCTO: "$MYf4$W1Z1v!D$YLX3$YL!XTo1Zwmy0H1F8Aw00y",
	JELLY: "1MYfU3$0$!f$T$$D$*v$^Y7TYlk5hwGF2EwGa",
	PURR: "gh4dw340fY1vQ1nMYvYnv$3$$1$U28Uo8wh5g120",
	BOO: "1MUfwT!0YxP$xE5xKnAeP$fD$`K7f$043c",
	DONUT: "70A9TFY$Lj$!7fXY",
	SAIL: "0gYc00o0v00s1$M1$1$Y$U00!f$!$!3$0020",
	BOY: "$Msa7MM6X!bX0fz!",
	UP: "fwAbZXs$$v$$fDPY008e",
	LUNAR: "3MQe0fU0U0fUv00f3!011$M0$TOM1$P30020",
	DINO: "030kY1Y0-!3Z0-$7Zw0*$LZM0*$$ZV0*$$ZXf070$$Z$vMrM$*wNPU$*0$z$-0$3$-h!3$-fU3$-7M3U$L$$7M00$*7M00f$WP7M20eVU30020eUU30020sNU700201M0f",
	APPLE: "1wAb3w86Y$$L$^Vw8S$",
	RING: "$MUe$f$$!$s0ou$vZHqZrZzr$HR$$MfMYf$$",
	EGG: "1MUg0j!0$0$!$$4$L$$D$`v$XvMv$UU07!",
	ROYAL: "10I9vssh$*!$^w3$!",
	FISH: "0wQb3P00b$X2!T$XZJ$$61w$0400",
	FLAKE: "0wQd13w0M6WgqOPo6KL9KU4d3F040200",
	SPECS: "01sbMc00g482g400jA00vE03$E0f$E6$!0f$U0f!w0fU003w",
	SUN: "0wQdy10200U8UM$U!6d$e80fx0w8020w",
	BIRD: "114e00Y07U0f$w3UMfY07$x$$0f!w4s1ke2x05wM0^i",
	GUN: "$MQ8L$$DM1fYv01$1!01",
	FLY: "$1An$NY0TVYNPV$8VZ$SYl$ZY5$$!f$^L$`!$^!f$L!0$$Y0$$!0$T$w$N$MWM$MZgvUswrY0gdP08s1M7e0M7A001M0",
	PLANE: "30Ice801Y0N7$J$$0oVU1z30",
	ALIEN: "80I8$yqi$D$T8Ez$0^2",
	FROG: "60Qc3!01z$$0!7$!vYfPj$wbcNP2003w",
	LIP: "01kb3w28$M7w$N$0$LY3$g1!NM$U3$P$fU3$vM3U",
	JAVA: "AwIfi289YgY0ZP$!f$$X$$TD$L7L080v",
	RAIN: "01kr1M0w$M30$N!0$fY1YvYvP$-X$`$`$-scUV02640g0w20^g0-80010^80-40g0A1w0w",
	DROP: "00Yf800g0u00Mwv1fUUfv7!s!$z!YevU3!0f00W0",
	BLUE: "018e2o0NU00w01410^4Uw40$fUhvZP$$f$P1$Z$vM3!003M",
	HOUSE: "0N0eM0f03$0f$3$M$$M$-cfMM$33YcfMM$33Y",
	PI: "vN4f$*4gPPMM26w6406c0Mc0Nw1NwexOsc!scP0fz1001U",
	SHELL: "o14av7w0DLYY$!$^f$X7$X$$M$$wcQ6",
	BUG: "o0U9A5Uq$x$Y$YifFvP4420F",
	UFO: "1N07!1$wrvM$^!S49MV0^8",
	CRAB: "g0QdJ0c6fW4f3b07$cvu$Yd$I$Q$00BF",
	ANT: "0hci504gy2d00fE21k1e$z3gz8Yp0fUf0M0s!020UfY04fdD3N1YU020^w0",
	PETAL: "01kl0M0wfw20Y040y8Y0w3A30dMtec1AY!e8Tr!vV$P!zEzz09c10dMt263e1U3Ufw30U0-80",
	LEAF: "01wp0^o0^o0g0Y0o2Z083$003$103$vy3$vTX$v$X$f$X$^P$v$^f$X$3$P$1$3$0!3$0Y3$0I3$040o0^o0^o0^o0^o0^o",
	HORSE: "11Qi0_1U0fw7UnY0$YvNvZ$$6$^!f$f$T$8$M763Nz!0e3$0fV$ufM70SU0o0b04w0O020101LoA6032gA0",
	CAR: "3Nge$M3$033M3033MM0M$MM0$-cf$MPYY$*6f$$w8410^o",
	PLUG: "c1go30f0M30*M30Y0Mc00c30f0YfY00fYf0Y0$fM3Y$0f0M3Y00fM30Y0$3M3YYf0^Mcfc30^fM00c0f",
	NOTE: "010oM0300f00$03M0fM0$M3$0cY0MM3f0cc0M0300c00M3$0PY0$Mc$0$Y3$03$0*$",
	CLEF: "00Ms0Ny01Dj014h01kP07$y0uK03VO07PvXcpdcQ62Cj07WMe0h14UN30e20",
	SHARK: "$i0g003Uof$^TY0c03w0f1$!fY7$MY01M3YV$-Y1$M4e0PP$!f$L$Yc3wgfM0K3$M9I1CM400g200c09U0^",
	APE: "01cifw3wM0s00fw0eM0l$M$K$LzzT$Z$T$*f7T3NZ!!e73C3$!66ffIef6",
	BIKE: "0hIi003w00sgA002UzY010g0gg60a010!00E8vy8y32h2AB98aE8gxb2O7Yogg13260^wEw8082wO20U0fw",
	PHONE: "1Nkg$M7$$N$-Y3U3LvYvZUfLL1Y1Guw3$$MuYHPXM$L!3!qI$N$^0fP$",
	TRAIN: "01Yf0^fM1Y07M0*2M07M00M0_w0c01gU0DesfXX$$aAAhALabZjtlv$XY$fDH$V$$zf$$JP760020",
	GIN: "0Nsre03$we4103g001w002M008Y1$MYu$M$U$M$w$w!0!0Y0U0Y0w0Y0^U001M003w0070^e0^c010803080f0s1$0Y3$0U3$0g3!003M",
	DEER: "01Ar0044w002o443oc00U3w0a2w0o0c0Y040u0^e0^v0^$LY0$$Y1$$Y1$$Y0$TY0$P!0$N!0UM!0ogq0o0q080b0c0dw205gxw0Egg0Q800i0818",
	TOOTH: "7Nkl$Ief$V$-Z$`$`$-Z$-Y$L$zUfYf0$Z$7$z$vc3vUMdY3wXw8200",
	BELL: "01so0^U0g3U1M3M3M3MfM3MvM3w$M70$Mf0$Mu0$NY0$zY0$7Y0$fY1$fY7!fYf!fY$!$^!$^Uf$$0^10^70^e0^8",
	TREE: "01YI0^400g030`00e01U07wY0$M401M3Y0fP$^Yvv$z00!f$Yf$^fYv$TYMZM7M0$z$!f$v$PQfVNPMsv3$Uf!v$Tf6Ugzz0YjZMfy1Y04f0hO0u83M0e41Mw7w0s03w0u07M0100c00w0Y07w0Y0040gw0^3w0c03w0s03M0`0f01U0f0`00Y07w0$0^1M0*3Y0fMvM0Y0",
	BULB: "1MYpU3$0$!f$T$$D$`$v$$RvfAwXLzn0uK3X06003wvU0$0vU0!0fY0!030020",
	TITAN: "02Uds7s710^MdQt400207tTgg08wdTt1M4w07tTvMi00LX$$Nc02fP$$YY$v$P$_v$_Yv$_z$~6fP$",
	CASH: "1gQk1Ow0VT$MD$9nEfOk$MfR2LMfYBfM$FivY7!Lg07!0^a",
	CLUB: "01Ap0^s080$0e0$0f0v0fMv0fUf0fY70fY1wfY0w3Y0zEY3D$c7T$T7$^P$^X$`$X$$ZV$$YYt$Us2v0833003w0g3U0M3$0e3$",
	SPADE: "01Ap0^80^e080f0e0f0fwf0fUfwfYfUfYf!fYf$LYf$$Yf$$!f$^D$^T$`$-X$^V$^Yv$$s7$uc3v0c3z0g3U0M3$0e3$",
	TRI: "01cl2010w0c0070^M0v$w3wM1Y00fM7fU3$$MvUsc013MUUYe637TZY!!ff$^!$*003!",
	NECK: "01oz040-a0^u0^S0M3Y3020s0^M040^U003w00e010^f0g0Y1M3Mfw20!040Y0Y0M3Y00fY30$M$3$3$nY3$vMb$L0DU0wrw2xy0a600E401w08500Ei0ix80a0g",
	CHAIR: "10Io7c00vc03ec4b8ggzDTP4$^n$vT$Nz6v4c8g0gx00200",
	PARIS: "01AJ0^80^40^208030e030a0^fw002w001M0^E0^s0w080M040E0^s0^a0^aw007M002w002g201g0w0M3g0g1o0g1Q001l083$041l0e0$0b0q05gl0bUf05k5MfY7EaE2k5k1$fY1GGE0$TY0Zlk0zjl0wNtww1XgM1GYM0FHU8Yq!eYf0fwf",
	BRASS: "03sq0g0`0-60-1M0-u0`M0*3M0-e0v0*MfYf0M00$*0020$$Yv003$$TY00fX$ZM40$fYs1M001Pye003Us3Me0f03u1M0U07we0*s3N0030wedN0801N$40^40!M0*2e0*$`$^0$*f!3$-Uf$$MdQ13M00$Mf0$*1U3$-0fX$^YfYf$$01M0",
	BUNNY: "01wp0^3080r0c0v0e0v0e0v0e0f0fM70fU10fU10fY10fY10fYv0fZ$0v$$0$X$1!3$1!3$1!3$r!3$$Y3$$Y3$$!3$^3$v$z$0fz$",
	R2: "01cmvM3wY1Y00fY7f!3$$Mv!!f$0T$Z$`$$f$TD$-f$$T$!!$$f!s$DL$$YZ$u$0030",
	TRUNK: "020jwfY3$!Y0$Mfw0$3$!f$x$$Qe!M$Uj$P$$f$X$$s$XMT!7fX$!L$^TZovVzWIfD$!fZ$$TZwtM3M0f3!U0VT3Ds0tM3w0e0eU0VT",
	HEEL: "01Qtw0c07w0^g0*3U0e0f0fY0$0Y0$M003M3w003$0fz$wfYf$fY0$MY0$M303M3e073Ywcf$3fMfUfY0$LZx$NY3$NDMPM3$1Lz$MfZ$$fYP$UY1vMPwog3!0$3$wfYf!3Yo$Y0*q0",
	YIN: "1N0g!1$wvfM$YDPO$$7$Mv$x!87$0v01sc9M13z21Nwu003w",
	PEACE: "01om0M3YYM3MP0cYc33MMc0M0Mf330Mc3P30fc00Yc0fMMc$30YPMPfc30Yfc30YMc0Mf^0YcM30fM00f03",
	BRAIN: "01YA03NUrHw3TgU0eg3K0cXptdpsHYs1fgcs2pynOa73vnYvNj7MmS0sgpIXBrrkX!T24UVKDQfuvNXfhT4rLApIXYjWKZttnQ1vZZVuNXCZbstJrliNL$!qECbHLGcy!HNWhxnPKTZVpZ6$gTdTSOCTT763vMlS1Zi$s603T$o3C0e00w3e0ezwM801",
	FLIP: "03Ay0M0*20_8030*M0s0-1M0*30_e070*Y0s0-1M0*3Y7M0^fX$0*$$Y$0020$TY00c3$vM00!f$$0*$$Z$003$$TY0Uf$$vM00$*003U$$Z$0fX$$PY0$*fM20$*0e3$^Yv$f$^NY0$*7M3$Yf$$MfY$0Y0f$M7Uw0fM0fM00fwvvw00$wc0_80v081$M0c0$MY0^200g3M0c3$01w0w7Y0-fM0*20_80v0*w0Y0-1M0*20_8030*w040`0^30",
	LOCK: "0hgnvM3U3N$wfc23NM4Us1W00es0070e$^L$`Yf$$1$X$v!3$$Mv!$^MYf!v1$X$-Yf$$",
	KEY: "f10807U0NM3C$Yv$vxXC03Ma",
	PINK: "1NgI!0s0MfY00fMf0c210g0ce030^s00e0^M0sc020^c0030-30TUMf$yv$Y7$$M$!$T$MIfY30u4v203owM30M0w00b0^g0cq020w0M00cw008080^82020w0-w0080-87020",
	BEN: "10I$4c01E002w0ck0xnU5vWw$$x3GBal$X!$vfb7Y8N7YgsvlvnTlek$kFmGjRmGfRnVGBjBGBal!kZlVjZlBqFklqFilvBflek$kFmGjRmGqL$XGL!$0fz$",
	NY: "014Xg0200200200g00U01w05c0r00Mw2ag7uw9g09k1a!3Uw2BgllgmGwqE2GA1lk5mw5qwGGwFk0Bk5lo6GEaF0aB1ll1mE1qEaGw9lg5m1lq2GG2Fg2BgllgmGwqE2GA1lk5mw5qwHGNFkOJYvlqmGGqFiGBhl$$!GvWHa0e3$",
	BUZZ: "030r0~v0-81$0g0^fz$1M0^fX$7M0^f$$vM0^f$^M0^f$^TY00f$*Y00fX$^Y13fP$^Y34!3$^Z$8g3Y$*gg3!$*wg3!$*3M3!$*203!$$Z$403$^Z$403$$TY!803$$XYt883!w6Y9043c06I1001C06820^z04o40^x08g0080g00w1080*w00400",
	EARTH: "02Mq0K1w6g00g01$WTY0U07w6fAf$$Yt8$AvUvZ$zM3!f$b$$Mvw!8f$vXA003$U$DY00c01Yf$P7U0c0f$v$Mdw0!3fX3$07w1I1fY1Y0588vwfM1Y0e80$0vM1o07M7I0f0080W05Y1M00w1O0u0081Y0*v0$0080700U1Y0*20fM0*w08010~g",
	KNIFE: "M24y0_1M03M07M0*200fwf$wc00g0^83$U7Y0fM0*3U0fUf$Mc0^200e3$!3Y03M0*3!wfY3$M0*3xMfA$$MY00M3w0e3$UfY0fM0*3M0fM3$M400g0^c3$Yes1og0*3Y07Y07M0*300e0f!0c0~3!07Y07M0*200807",
	FIRE: "01kl0g0wfw00$0s0s780M1060Udwc1w0wm40D3Y3g63!1w33o00c0odw1P247M0Dfw3Mg0-80",
	LOGO: "eOEi79QPGIzpllmxI2F2k9lt4uGceCllTpSH0cil0^2g0810020o9xx20*11CfMc0^3c$DY00M00$f$$DM0^fD$$MY0^3$$DY20010AfOj10*20*40*8",
	CHECK: "01gg0c0^M0uvM3MUgY0efM1NUs$$LXMMfY70vw$3M3$v03ww0c00100",
	NO: "0hcjMg3M2^0ww08w2w00908cjokcqyaeIplpIm8100kyw28w20^ww8wc400c07",
	PRIZE: "fN0j!1$Yv$wv!p$!D$Cv!p$Vf$9fU0vY0s03w0601U01Y0$w3$0f003M",
	SOUND: "0h8fM0^4700!O8QOb449$6v$QDYN7Yi9fav1N8YM8080110",
	STAT: "oMwfpLvP70Mec3woXSpM0crf",
	SPACE: "00UeY04sVI0vvVw77$$7ML$U!05!FY0h0303",
	CHESS: "5wEeYYc7I$$!$f3xNMY$f$PU0f0$",
	DART: "80Ue3sU071E!exwsS6hCj6hj0mfe3NO700Xw",
	LIKE: "01sl0c0^c0308070^v0^!0g3Y1P$Mf$$w$^Z$`$^L$`$LZ$$v$$Y$^U$^M$$V0f$w",
	SHARE: "fNcj!8$$3$VX$Y7$$P$UU$Yv7$3$$Mf!!7$0N$Yf$$z$$N$^f$z$$Z$!f$L0fX$",
	CHIRP: "fMQeL$$z$!v$f$w$v$X1w$$P$N$!7$$T003M",
};