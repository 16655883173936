export const colors = [
	[105,55,173],[65,7,143],[157,40,189],[112,61,125],
	[168,41,118],[221,59,157],[208,37,37],[0,74,149],
	[57,212,168],[40,203,227],[20,205,59],[255,213,39],
	[223,112,0],[182,250,29],[55,67,172],[42,147,64],
	[128,255,128],[42,140,248],[200,6,10],[255,68,0],
	[255,0,128],[58,205,98],[0,198,189],[16,107,177],
	[225,0,39],[143,170,0],[13,127,44],[0,250,251],
	[255,128,255],[0,232,35],[128,0,64],[242,93,49],
	[255,237,0],[207,45,255],[224,119,255],[240,0,0],
	[255,51,51],[199,228,27],[237,57,167],[183,123,160],
	[255,128,192],[0,128,128],[112,146,190],[255,128,0],
	[128,128,192],[249,200,30],[44,32,255],[237,46,18],
	[21,36,174],[255,154,53],[221,0,111],[82,197,33]
];

const ff = value => parseInt(value).toString(16).padStart(2,'0');
const callbacks = {
	'255,255,255': color => color.join(','),
	'#ffffff': color => '#'.concat(...color.map(ff)),
	'[1,1,1,1]': color => color.map(value => value/255).concat(1),
	'0xffffff': color => parseInt('0x'.concat(...color.map(ff)))
};

export const refactorColors = format => colors.map(callbacks[format]);