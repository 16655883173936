/*
0 AIR
1 BLOCKY
2 WALL
3 ENEMY
4 BLINK	// TODO support changing to water instead of air if adjacent spaces are water
5 SPIKE
6 TRAMPOLINE
7 WATER
8 PIPE
9 LOCK
A (10) KEY
B (11) CRUMBLING_PLATFORM
C (12) BLINKING SPIKE
*/

const
A = 10,
B = 11,
C = 12;

export const levels = [
	[
		5,
		0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,
		0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,
		0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,
		0,1,0,0,0,C,0,0,C,0,0,C,0,0,C,0,0,C,0,0,C,0,0,0,
		2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2
	],
	[
		10,
		2,0,0,0,0,0,8,0,0,0,7,7,7,7,0,
		2,0,0,0,0,0,0,0,0,0,7,7,7,7,0,
		2,0,0,0,0,0,0,0,0,0,7,7,7,7,0,
		2,0,0,0,0,0,0,0,0,0,7,7,7,7,0,
		2,0,0,0,0,0,0,0,0,0,7,7,7,2,2,
		2,0,0,0,1,0,0,0,0,0,7,7,7,7,2,
		2,2,2,2,2,2,2,2,2,6,7,7,7,7,2,
		2,0,0,0,0,0,0,0,0,0,7,7,7,7,2,
		8,0,0,0,0,0,0,0,0,0,7,7,7,7,2,
		2,2,2,2,2,2,2,2,2,2,7,7,7,7,2
	],
	[
		11,
		2,7,7,7,7,7,7,7,7,7,7,7,7,7,7,2,
		2,7,7,7,7,7,7,7,7,7,7,7,7,7,7,2,
		2,7,7,7,7,7,7,7,7,7,7,7,7,7,7,2,
		2,7,7,7,7,7,7,7,7,7,7,7,7,7,7,2,
		2,7,7,7,7,7,7,7,7,7,7,7,7,7,7,2,
		2,7,7,7,7,7,7,7,7,7,7,7,7,7,7,7,
		2,7,7,7,7,7,2,2,7,7,2,2,2,2,2,2,
		2,2,6,2,2,1,7,7,7,7,7,7,7,7,7,2,
		2,2,2,2,2,2,2,2,7,7,7,7,7,7,7,2,
		2,2,2,2,2,2,2,2,7,7,2,2,2,2,2,2,
		2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2
	],
	[
		7,
		0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,
		2,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,
		2,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,2,
		2,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,2,
		2,2,2,2,0,0,4,4,4,2,2,2,2,2,6,2,2,
		2,1,0,0,0,0,0,0,0,0,0,0,2,0,0,0,0,
		2,2,2,2,2,2,2,6,2,2,2,2,2,0,0,0,0
	],
	[
		8,
		2,0,0,0,0,0,2,
		2,0,2,0,0,0,2,
		2,1,0,0,0,0,2,
		2,2,0,2,2,2,2,
		2,0,0,0,0,0,2,
		2,0,0,0,0,0,2,
		2,0,0,0,0,0,0,
		2,2,2,2,2,2,2
	],
	[
		5,
		2,0,0,0,0,0,0,0,0,0,0,0,0,0,
		2,0,0,0,0,0,0,0,0,0,0,0,0,0,
		2,0,0,0,0,0,0,0,0,0,0,0,0,2,
		2,1,0,0,0,0,0,0,0,0,0,0,0,2,
		2,6,6,6,6,2,2,2,2,2,2,6,6,2,
	],
	[
		8,
		0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,
		2 ,0,0,0,0,0,0,0,0,0,0,0,0,0,0,
		2,1,0,0,0,0,0,0,0,0,0,0,0,0,0,
		2,2,2,2,2,2,2,2,2,2,2,0,0,0,2,
		2,0,0,0,0,0,0,0,0,0,0,0,0,0,2,
		2,0,0,0,0,0,0,0,0,0,0,0,0,0,2,
		2,0,0,0,0,0,0,0,0,0,0,0,0,0,0,
		2,2,2,2,B,B,B,B,2,2,2,2,2,2,2
	],
	[
		8,
		0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,
		2,0,0,0,0,0,0,0,0,0,0,0,0,0,0,
		2,0,0,0,0,0,0,0,0,0,0,0,0,0,0,
		2,0,0,0,1,0,0,0,0,0,0,0,0,0,2,
		2,2,2,2,2,2,2,2,2,2,2,0,0,0,2,
		2,0,0,0,0,0,0,0,0,0,0,0,0,0,2,
		2,A,0,0,0,0,0,0,0,0,0,0,0,0,9,
		2,2,2,2,2,2,2,2,2,2,2,2,2,2,2
	],
	[
		9,
		0,0,0,0,0,0,0,2,
		1,0,0,0,0,0,0,2,
		2,2,2,0,2,0,2,2,
		2,0,0,0,0,0,0,2,
		2,0,0,0,0,0,0,2,
		2,2,0,2,0,2,2,2,
		2,0,0,0,0,0,0,2,
		2,0,0,0,0,0,0,0,
		2,2,2,2,2,2,2,2,
	],
	[
		4,
		0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,
		0,0,0,0,2,0,2,0,0,2,0,2,0,0,0,
		0,1,0,0,0,2,0,0,0,0,2,0,0,0,0,
		2,2,2,2,2,2,2,2,2,2,2,2,2,2,2
	],
	[
		5,
		2,0,0,0,0,0,0,0,0,0,0,0,0,0,
		2,1,0,0,0,0,0,0,0,0,0,0,0,0,
		2,2,0,0,0,0,0,0,0,0,0,0,0,0,
		2,2,2,0,0,0,0,0,0,0,0,0,0,2,
		2,2,2,2,2,2,2,2,2,2,2,2,2,2
	],
	[
		5,
		0,0,0,0,0,0,0,0,0,0,0,0,0,0,
		0,0,0,0,0,0,0,0,0,0,0,0,0,0,
		1,0,0,0,0,0,0,0,0,0,0,0,0,2,
		2,0,0,0,0,0,2,2,2,0,0,3,0,2,
		2,2,2,2,2,2,2,2,2,2,2,2,2,2
	],
	[
		5,
		0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,
		1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,
		2,0,0,0,0,0,0,0,0,0,0,0,0,0,2,
		2,0,0,0,0,0,3,0,0,3,0,0,3,0,2,
		2,2,2,2,2,2,2,2,2,2,2,2,2,2,2
	],
	[
		3,
		0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,
		1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,
		2,2,0,0,2,0,2,2,2,2,2,2,2,2,2
	],
	[
		6,
		0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,
		0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,
		0,0,0,0,0,0,0,0,0,0,0,0,0,2,2,
		0,0,0,0,0,0,0,0,0,0,0,2,2,2,2,
		1,0,0,0,0,0,0,0,0,2,2,2,2,2,2,
		2,2,2,2,2,2,2,2,2,2,2,2,2,2,2
	],
	[
		12,
		2,0,0,0,0,0,0,0,0,0,2,2,
		2,1,0,0,0,0,0,0,0,2,2,2,
		2,2,2,2,0,0,0,0,2,2,2,2,
		2,2,2,2,0,0,0,0,2,2,2,2,
		2,2,0,0,0,0,0,0,2,2,2,2,
		2,0,0,2,2,0,0,0,0,2,2,2,
		2,2,0,0,0,0,0,0,2,0,2,2,
		2,2,2,2,2,2,0,0,2,0,0,2,
		2,2,2,2,0,0,0,0,0,0,2,2,
		2,2,2,2,0,0,0,0,2,0,2,2,
		2,2,2,2,0,0,0,2,2,2,0,0,
		2,2,2,2,0,0,0,0,2,2,2,2,
	]
];